/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { SENT_CHECK_LISTS, SentCheckListsQuery } from "graphql/queries";
import { CheckListStatusEnum, CheckListsFilter } from "graphql/types";

import { BulkInput } from "./shared/types";

const CANCEL_CHECK_LISTS = gql`
  mutation ($ids: [ID!], $filter: CheckListsFilter) {
    cancelCheckLists(input: { ids: $ids, filter: $filter }) {
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

type Input = BulkInput<CheckListsFilter>;

interface Payload {
  errors: ErrorFragment[];
}

interface CancelCheckListsMutation {
  cancelCheckLists: Payload | null;
}

export const useCancelCheckListsMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CancelCheckListsMutation,
    Input
  >(CANCEL_CHECK_LISTS);

  const cancelCheckLists = (variables: Input, onSuccess?: () => void) =>
    mutation({
      variables,
      update(cache) {
        cache.modify({
          fields: {
            sentCheckLists() {
              const existingCheckLists = cache.readQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
              });
              if (!existingCheckLists?.sentCheckLists.items) return;

              const isSome = Object.prototype.hasOwnProperty.call(
                variables,
                "ids"
              );
              const ids = isSome ? (variables as { ids: string[] }).ids : [];

              const items = existingCheckLists.sentCheckLists.items?.map(
                (checkListItem) => ({
                  ...checkListItem,
                  status:
                    !isSome || !ids.includes(checkListItem.id)
                      ? CheckListStatusEnum.Cancelled
                      : checkListItem.status,
                })
              );

              cache.writeQuery<SentCheckListsQuery>({
                query: SENT_CHECK_LISTS,
                data: {
                  sentCheckLists: {
                    ...existingCheckLists.sentCheckLists,
                    items,
                  },
                },
              });
            },
          },
        });
      },
    })
      .then(({ data, errors }) => {
        const dataErrors = data?.cancelCheckLists?.errors ?? [];

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess,
          onRetry: () => cancelCheckLists(variables, onSuccess),
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    cancelCheckLists,
    isLoading: loading,
    errors: data?.cancelCheckLists?.errors ?? [],
  };
};
