import { Fragment, useContext } from "react";

import moment from "moment";

import { Button, Divider, Icon, Link } from "components/common/basic";
import { DocumentStatusLabel } from "components/common/composite/StatusLabel";
import {
  MenuItemAction,
  MenuItemLink,
} from "components/common/wrappers/Layout/common/MenuList/types";
import { useDocumentActions } from "components/documents/hooks";
import { DocumentFragment } from "graphql/fragments";
import {
  useNotifySignatureMutation,
  useSignDocumentMutation,
} from "graphql/mutations";
import {
  AccountTypeEnum,
  DocumentStatusEnum,
  SignatureStatusEnum,
} from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { atDateToMoment, getNameFromAccount } from "utils";
import { tw } from "utils/tw";

import Attachment from "./Attachment";

interface Props {
  document: DocumentFragment;
}

export default ({ document }: Props): JSX.Element => {
  const { notifySignature, isLoading: isNotifying } =
    useNotifySignatureMutation();
  const { signDocument, isLoading: isSigning } = useSignDocumentMutation();
  const { openPopup } = useContext(PopupContext);
  const { getDocumentActions } = useDocumentActions();

  const { t } = useTranslation("documents");

  const createdAtDate = document.createdAt
    ? atDateToMoment(document.createdAt).format("DD MMM YYYY")
    : "-";

  const isDraft = document.status === DocumentStatusEnum.Draft;
  const isCancelled = document.status === DocumentStatusEnum.Cancelled;
  const isPending = document.status === DocumentStatusEnum.Pending;

  const documentFiles = document.signedDocument
    ? [
        {
          id: document.signedDocument.id,
          name: document.signedDocument.filename ?? "",
          file: document.signedDocument,
        },
      ]
    : document.documentFiles;

  return (
    <div
      className={tw(
        "w-full",
        "rounded-lg",
        "border",
        "border-silver",
        "overflow-hidden",
        "flex",
        "flex-col",
        "space-y-4"
      )}
    >
      <div className={tw("bg-silver", "py-6", "px-4", "space-y-4")}>
        <div
          className={tw(
            "flex",
            "flex-col",
            "gap-2",
            "md:flex-row",
            "md:justify-between"
          )}
        >
          <h2
            className={tw(
              "text-lg",
              "text-deepBlue-900",
              "font-bold",
              "break-words"
            )}
          >
            {document.name}
          </h2>

          <p className={tw("text-sm", "text-deepBlue-500")}>
            {t("document.date.createdAt", "Created at: {{ date }}", {
              date: createdAtDate,
            })}
          </p>
        </div>

        {document.senderAccount && (
          <div className={tw("flex", "space-x-4")}>
            <Icon icon="HiPaperAirplane" isFilled margin="mt-1" />

            <div className={tw("grid", "gap-1")}>
              <p className={tw("text-deepBlue-900", "font-semibold")}>
                {t("document.sender.label", "Sender:")}
              </p>

              {document.account.accountType === AccountTypeEnum.Team && (
                <p className={tw("text-deepBlue-700", "text-sm", "truncate")}>
                  {getNameFromAccount(document.account)}
                </p>
              )}

              <p className={tw("text-deepBlue-700", "text-sm", "truncate")}>
                {getNameFromAccount(document.senderAccount)}
                {document.senderAccount.primaryUser?.email &&
                  ` - ${document.senderAccount.primaryUser.email}`}
              </p>
            </div>
          </div>
        )}
      </div>

      <div className={tw("p-4", "space-y-4")}>
        <div className={tw("flex", "justify-between")}>
          <h3 className={tw("text-lg", "text-deepBlue-900", "font-bold")}>
            {t("document.header", "Document")}
          </h3>

          {document?.status && (
            <div className={tw("flex", "flex-col", "items-end", "space-y-1")}>
              <DocumentStatusLabel variant="tag" status={document.status} />

              <p className={tw("text-xs", "text-deepBlue-500")}>
                {t(
                  "document.date.lastInteraction",
                  "Last updated: {{ date }}",
                  { date: moment().format("DD MMM YYYY") }
                )}
              </p>
            </div>
          )}
        </div>

        <div className={tw("flex", "flex-col", "md:flex-row", "gap-4")}>
          {getDocumentActions(document).map((action) => {
            if (Object.prototype.hasOwnProperty.call(action, "onClick")) {
              const { onClick } = action as MenuItemAction;

              return (
                <Button
                  key={action.id}
                  id={action.id}
                  LeadingIcon={action.Icon}
                  onClick={onClick}
                  variant="tertiary"
                  size="sm"
                >
                  {action.label}
                </Button>
              );
            }

            const { to, isExternal } = action as MenuItemLink;

            return (
              <Link
                key={action.id}
                id={action.id}
                unstyled
                to={to}
                isExternal={isExternal}
              >
                <Button
                  id={action.id}
                  LeadingIcon={action.Icon}
                  variant="tertiary"
                  size="sm"
                  fullWidth
                >
                  {action.label}
                </Button>
              </Link>
            );
          })}
        </div>

        {documentFiles.length > 0 && (
          <>
            <Divider />

            <div
              className={tw(
                "-mr-4",
                "-mb-4",
                "flex",
                "justify-start",
                "flex-wrap"
              )}
            >
              {documentFiles.map((file) => (
                <div key={file.id} className={tw("mr-4", "mb-4")}>
                  <Attachment documentFile={file} />
                </div>
              ))}
            </div>
          </>
        )}

        <Divider />

        <div className={tw("space-y-4")}>
          <h3 className={tw("text-lg", "text-deepBlue-900", "font-bold")}>
            {t("document.recipients", "Signees")}
          </h3>

          <ul className={tw("space-y-4")}>
            {[...document.bulkSignatures, ...document.signatures].map(
              (signature, index) => (
                <Fragment key={signature.id}>
                  {index > 0 && (
                    <li>
                      <Divider />
                    </li>
                  )}

                  <li>
                    <div
                      className={tw("flex", "flex-col", "md:flex-row", "gap-3")}
                    >
                      <div
                        className={tw(
                          "flex",
                          "space-x-1",
                          "items-center",
                          isPending ? "md:w-4/12" : "md:w-5/12"
                        )}
                      >
                        {signature.account?.symbolDisplayPath && (
                          <span className={tw("shrink-0")}>
                            <img
                              className={tw(
                                "rounded-full",
                                "w-6",
                                "h-6",
                                "object-cover"
                              )}
                              src={`${process.env.REACT_APP_API_URL}${signature.account.symbolDisplayPath}`}
                              alt="Member avatar"
                            />
                          </span>
                        )}

                        <div
                          className={tw(
                            "flex",
                            "flex-col",
                            "space-y-1",
                            "overflow-hidden"
                          )}
                        >
                          <p
                            className={tw(
                              "text-xs",
                              "font-semibold",
                              "text-deepBlue-900",
                              "truncate"
                            )}
                          >
                            {signature.name.full}
                          </p>
                          <p
                            className={tw(
                              "text-xs",
                              "text-deepBlue-900",
                              "truncate"
                            )}
                          >
                            {signature.email}
                          </p>
                        </div>
                      </div>

                      <div
                        className={tw(
                          isPending ? "md:w-4/12" : "md:w-5/12",
                          "flex",
                          "flex-col",
                          "space-y-1"
                        )}
                      >
                        <p className={tw("text-xs", "text-deepBlue-700")}>
                          {t(
                            "document.recipients.signature.signedAt",
                            "Signed: {{ date }}",
                            {
                              date: signature.signedAt
                                ? atDateToMoment(signature.signedAt).format(
                                    "DD MMM YYYY"
                                  )
                                : t(
                                    "document.recipients.signature.pending",
                                    "Pending"
                                  ),
                            }
                          )}
                        </p>
                        <p className={tw("text-xs", "text-deepBlue-700")}>
                          {t(
                            `document.recipients.signatureType.${signature.document.type.toLowerCase()}`,
                            `Signing method: ${signature.document.type}`
                          )}
                        </p>
                      </div>

                      <div className={tw("md:w-2/12")}>
                        {!isDraft && !isCancelled && (
                          <DocumentStatusLabel
                            variant="tag"
                            status={signature.status}
                          />
                        )}
                      </div>

                      {isPending && (
                        <div className={tw("md:w-2/12")}>
                          {signature.status === SignatureStatusEnum.Pending &&
                            (signature.signUrl ? (
                              <Link
                                id="published_recipients-sign"
                                to={signature.signUrl}
                                isExternal
                                openSeparate={false}
                              >
                                <Button
                                  id="published_recipients-sign"
                                  fullWidth
                                  variant="tertiary"
                                  size="md"
                                >
                                  {t(
                                    "document.recipients.action.signDocument",
                                    "Sign"
                                  )}
                                </Button>
                              </Link>
                            ) : signature.canSign ? (
                              <Button
                                id="published_recipients-sign"
                                fullWidth
                                variant="tertiary"
                                size="md"
                                onClick={() =>
                                  openPopup({
                                    id: "Signature",
                                    props: {
                                      signature,
                                      onSignature: (signatureData) => {
                                        openPopup({
                                          id: "StepLoader",
                                          props: {
                                            title: t(
                                              "eSignatures.fakeLoader.title",
                                              "Quick e-sign"
                                            ),
                                            steps: [
                                              t(
                                                "eSignatures.fakeLoader.step1",
                                                "Document uploading"
                                              ),
                                              t(
                                                "eSignatures.fakeLoader.step2",
                                                "Document verification"
                                              ),
                                              t(
                                                "eSignatures.fakeLoader.step3",
                                                "Sign document"
                                              ),
                                            ],
                                            action: (onSuccess, onError) =>
                                              signDocument(
                                                {
                                                  id: signature.id,
                                                  attributes: {
                                                    firstName:
                                                      signatureData.firstName,
                                                    lastName:
                                                      signatureData.lastName,
                                                    digitalSignature: {
                                                      data: signatureData.data,
                                                    },
                                                  },
                                                },
                                                onSuccess,
                                                onError
                                              ),
                                            isAutoComplete: true,
                                          },
                                        });
                                      },
                                    },
                                  })
                                }
                                disabled={isSigning}
                              >
                                {t(
                                  "document.recipients.action.signDocument",
                                  "Sign"
                                )}
                              </Button>
                            ) : document.owned ? (
                              <Button
                                id="published_recipients-remind"
                                fullWidth
                                variant="tertiary"
                                size="md"
                                onClick={() => notifySignature(signature.id)}
                                disabled={isNotifying}
                              >
                                {t(
                                  "document.recipients.action.remindDocument",
                                  "Remind"
                                )}
                              </Button>
                            ) : null)}
                        </div>
                      )}
                    </div>
                  </li>
                </Fragment>
              )
            )}
          </ul>
        </div>

        {document.copyRecipients.length > 0 && (
          <>
            <Divider />

            <div className={tw("space-y-4")}>
              <h3 className={tw("text-lg", "text-deepBlue-900", "font-bold")}>
                {t("document.copyRecipients", "Signed copy should be sent to:")}
              </h3>

              <p className={tw("text-sm", "text-deepBlue-500", "truncate")}>
                {document.copyRecipients.join(", ")}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
