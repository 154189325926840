import { gql } from "@apollo/client";

import { Check, CheckFields, CheckListStatusEnum, Event } from "graphql/types";

import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";

export const CHECK_FRAGMENT = gql`
  fragment CheckFragment on Check {
    id
    type
    status
    fields {
      accountNumber
      expiryDate
      firstName
      issueDate
      lastName
      nationality
      passportNumber
      identifier
      obtainedAt
    }
    files {
      ...AttachmentFragment
    }
    checkList {
      id
      status
    }
    events {
      occurredAt
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export interface CheckFragment extends Pick<Check, "id" | "status" | "type"> {
  fields: Pick<
    CheckFields,
    | "accountNumber"
    | "expiryDate"
    | "firstName"
    | "issueDate"
    | "lastName"
    | "nationality"
    | "passportNumber"
    | "identifier"
    | "obtainedAt"
  >;
  files: AttachmentFragment[];
  checkList: {
    id: string;
    status: CheckListStatusEnum;
  };
  events: Pick<Event, "occurredAt">[];
}
